<script>
	import Base from '@backend/Base.vue';

	export default {
		name: "BoUsers",
		extends: Base,
		computed: {
			filterLevel: {
				get() {
					return this.filter.level;
				},
				set(newValue) {
					this.filter = {
						...this.filter,
						level: newValue
					};
					this.search();
				}
			}
		},
		data() {
			return {
				Name: "BoUsers",
				mrLevel: {},
        dataLength: 0
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
      this.moduleRedirect()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row gutter-10">
								<div class="col-sm-2">
									<h5 class="card-title">Daftar {{ObjectName}}</h5>
								</div>
								<div v-if="dataLength > 0" class="col-lg-2 col-sm-4 ml-auto">
									<select2 :options="mrLevel" :object="['bul_id','bul_name']" v-model="filterLevel">
										<option value="">-- Pilih Level --</option>
									</select2>
								</div>
								<div v-if="dataLength > 0" class="col-9 col-sm-4 col-lg-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
												placeholder="Cari...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div v-if="dataLength > 0" class="col-3 col-sm-auto">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										Nama Lengkap
									</th>
									<th>Username</th>
									<th>Level</th>
									<th>{{fields.bu_is_active}}</th>
									<th width="120px" v-if="moduleRole('Edit') || moduleRole('Delete')">Aksi</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.bu_full_name}}</td>
									<td>{{v.bu_username}}</td>
									<td>{{v.levelname}}</td>
									<td>
										<StatusLabel :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action" v-if="moduleRole('Edit') || moduleRole('Delete')">
										<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
										<a v-if="page.moduleRole('Ubah')" href="javascript:;" class="icon_action"
											@click="changeStatus(k)" v-tooltip="'Ubah Status'"><i class="icon-settings"></i></a>
										<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action"
											@click="deleteItem($event,k)" v-tooltip="'Hapus'"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h5 class="tc">{{NotFound}}</h5>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-body">
						<Pagination class="justify-content-end m-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{row.id?"Ubah":"Tambah"}} {{ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-5">
							<BoField name="bu_full_name" v-bind="validation('bu_full_name')" v-model="row.bu_full_name" :label="'Nama Lengkap'" :attr="{placeholder: 'e.g. Galih Kusuma'}"></BoField>
							<BoField name="bu_email_address" v-bind="validation('bu_email_address')" onkeydown="return emailKey(event)" v-model="row.bu_email_address" :attr="{type:'email', placeholder: 'e.g. galih@gmail.com'}"></BoField>
							<BoField name="bu_username" v-bind="validation('bu_username')" onkeydown="return alphaNumber(event)" v-model="row.bu_username" keyFilter="usernameKey" :attr="{placeholder: 'e.g. galihkusuma'}">
							</BoField>
							<BoField name="bu_password" v-bind="validation('bu_password')" v-model="row.bu_password" :attr="{type:'password', placeholder: 'e.g. galihkusuma123'}"></BoField>
						</div>
						<div class="col-sm-4">
							<BoField name="bu_phone" v-bind="validation('bu_phone')" :attr="{type:'tel', placeholder: 'e.g. 08821989287', maxlength: '13'}" v-model="row.bu_phone" :label="'No Telepon'" keyFilter="mobileKey"></BoField>
							<BoField name="bu_level" :label="'Nama Level'">
								<select2 name="bu_level" v-bind="validation('bu_level')" :options="mrLevel"
									:object="['bul_id','bul_name']" v-model="row.bu_level">
									<option value="">-- Pilih Level --</option>
								</select2>
							</BoField>
							<div class="row">
								<div class="col-7">
									<BoField name="bu_is_active">
										<radio name="bu_is_active" v-model="row.bu_is_active" option="Y"
											:attr="validation('bu_is_active')">Active</radio>
										<radio name="bu_is_active" v-model="row.bu_is_active" option="N">Inactive</radio>
									</BoField>
								</div>
							</div>
						</div>
						<div class="col-sm-3">
							<BoField name="bu_pic">
								<Uploader name="bu_pic" type="users" uploadType="cropping" :deleted="false" :param="{thumbnail:true}"
									v-model="row.bu_pic"></Uploader>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
                <router-link
                  :to="{name: $route.name}"
                  class="fcbtn btn btn-light btn-outline btn-1e btn-rounded mr-2"
                >
                  Kembali
                </router-link>
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-loading">{{$route.params.id=='add'?'Tambah':'Ubah'}}</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>